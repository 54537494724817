import React from "react";
import { Avatar, Button, Card, Col, Divider, Row } from "antd";
import { Link } from "react-router-dom";
import { logOut } from "@Bitreel/portal-login";
import { sharedInterface } from "@Bitreel/portal-login";

export default class UserDropdown extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <Card style={{ width: 300 }}>
          <Row align="middle">
            <Col span={6}>
              <Avatar size="large" gap={2}>
                {sharedInterface().user.email.substring(0, 2).toUpperCase()}
              </Avatar>
            </Col>
            <Col span={12}>{sharedInterface().user.email}</Col>
          </Row>
          <Divider />
          {/*<Row align="middle">*/}
          {/*  <Link to="/home" className="link">*/}
          {/*    <p>menu item1</p>*/}
          {/*  </Link>*/}
          {/*</Row>*/}
          {/*<Row align="middle">*/}
          {/*  <Link to="/home" className="link">*/}
          {/*    <p>menu item2</p>*/}
          {/*  </Link>*/}
          {/*</Row>*/}
          {/*<Row align="middle">*/}
          {/*  <Link to="/home" className="link">*/}
          {/*    <p>menu item3</p>*/}
          {/*  </Link>*/}
          {/*</Row>*/}
          {/*<Row align="middle">*/}
          {/*  <Link to="/home" className="link">*/}
          {/*    <p>menu item4</p>*/}
          {/*  </Link>*/}
          {/*</Row>*/}
          <Divider />
          <Row justify="center" align="middle">
            <Button
              shape="round"
              className="bitreel-button"
              size="large"
              onClick={() => {
                logOut();
              }}
            >
              LOG OUT
            </Button>
          </Row>
        </Card>
      </>
    );
  }
}
