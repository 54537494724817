import React from "react";
import { Layout } from "antd";
import { BrowserRouter } from "react-router-dom";
import { sharedInterface } from "@Bitreel/portal-login";

import "./root.component.less";
import Navigation from "./navigation";
import { LOGIN_URL, NO_AUTH_URLS } from "../constants";

export default class Root extends React.Component {
  constructor(props) {
    super(props);
    const auth = sharedInterface;
    if (!auth().token && !NO_AUTH_URLS.includes(window.location.pathname)) {
      window.location.href = window.location.origin + LOGIN_URL;
    }
  }

  render() {
    return (
      <section>
        <BrowserRouter>
          <Layout className="layout">
            <Navigation />
          </Layout>
        </BrowserRouter>
      </section>
    );
  }
}
