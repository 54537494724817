export const BACKEND_URL =
  window.location.hostname === "localhost" ||
  (window.location.hostname === "portal-beta.bitreel.com" &&
    window.location.protocol === "http:")
    ? "http://localhost:5000/api"
    : window.location.hostname === "cms-dev.bitreel.com" ||
      (window.location.hostname === "portal-beta.bitreel.com" &&
        window.location.protocol === "https:")
    ? "https://products-api-beta.bitreel.com/api"
    : "https://api.bitreel.com/api";

export const LOGIN_URL = "/login";
export const NO_AUTH_URLS = [];
