import React from "react";
import { Avatar, Dropdown, Layout, Menu, Image } from "antd";
const { Header } = Layout;
import logo from "../asset/logo.png";
import UserDropdown from "./userdropdown";
import { sharedInterface } from "@Bitreel/portal-login";

export default class Navigation extends React.Component {
  render() {
    return (
      <Header
        className="portalGlobalNavHeader"
        style={{
          position: "fixed",
          width: "100%",
          filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
          zIndex: 1,
        }}
      >
        <div className="logo">
          <Image
            preview={false}
            className="logo_img"
            alt="bitreel"
            src={logo}
            onClick={() => {
              window.history.pushState(null, null, `/home`);
            }}
          />
        </div>
        <div className="user">
          <Dropdown overlay={<UserDropdown />} placement="bottomRight" arrow>
            <Avatar size="large" gap={2}>
              {sharedInterface().user.email.substring(0, 2).toUpperCase()}
            </Avatar>
          </Dropdown>
        </div>
        <Menu
          className="portalGlobalNavHorizontalMenu"
          mode="horizontal"
          style={{ height: "100%" }}
          onClick={({ item, key, keyPath, domEvent }) =>
            window.history.pushState(null, null, `/${key}`)
          }
          defaultSelectedKeys={[
            window.location.pathname.split("/")[1] || "home",
          ]}
        >
          <Menu.Item key="home">HOME</Menu.Item>
          {/*<Menu.Item key="showrooms">SHOWROOMS</Menu.Item>*/}
          <Menu.Item key="products">PRODUCTS</Menu.Item>
          {/*<Menu.Item key="ecommerce">E-COMMERCE</Menu.Item>*/}
          {/*<Menu.Item key="analytics">ANALYTICS</Menu.Item>*/}
          {/*<Menu.Item key="cobrowsing">CO-BROWSING</Menu.Item>*/}
        </Menu>
      </Header>
    );
  }
}
